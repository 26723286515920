<template>
    <div class="confirm-mobile">
        <div class="mobile-img">
            <svg @click="$router.push('/Login').catch(() => {})" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                <g id="Group_2278" data-name="Group 2278" transform="translate(-131 -108)">
                    <g id="Rectangle_1140" data-name="Rectangle 1140" transform="translate(131 108)" fill="#fff" stroke="#707070" stroke-width="0.5">
                    <rect width="34" height="34" rx="4" stroke="none"/>
                    <rect x="0.25" y="0.25" width="33.5" height="33.5" rx="3.75" fill="none"/>
                    </g>
                    <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M13.662,12.972l5.295-5.127a.944.944,0,0,0,0-1.369,1.027,1.027,0,0,0-1.416,0l-6,5.809a.946.946,0,0,0-.029,1.336l6.024,5.85a1.029,1.029,0,0,0,1.416,0,.944.944,0,0,0,0-1.369Z" transform="translate(132.749 111.806)"/>
                </g>
            </svg>
            <img src="@/assets/images/elements/confirm mobile.png" alt="Mobile">
        </div>
        <h5>ادخل البريد الإلكتروني</h5>
        <p>من فضلك ادخل بريدك الإلكتروني في تربو لنرسل لك كود التحقيق علي رقم الهاتف المسجل في حسابك</p>
        <div class="confirm-input">
            <label>البريد الإلكتروني</label>
            <input v-model="email" :style="!successData ? {'border': '1px solid red'} : ''" type="email" placeholder="ادخل البريد الإلكتروني الخاص بك في تربو">
            <span class="err-msg" v-if="!successData">{{ error_msg }}</span>
            <button @click="googleEventTrack('الضغط على ارسال الكود', 'login', 'send_code'), sendCode()" :class="!email ? 'opacity-btn1' : ''" :disabled="!email" v-if="!loading">إرسال الكود</button>
            <button class="btn-login w-full flex justify-center items-center" v-else> <loadSpiner></loadSpiner> </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import loadSpiner from "@/views/components/table-components/SpinnerColor2"
import gTag from "@/mixins/gtag.js"

export default {
    mixins: [gTag],
    data () {
        return {
            email: '',
            loading: false,
            successData: true
        }
    },
    computed: {
        ...mapGetters('auth', ["success", "error_msg"])
    },
    components: {
        loadSpiner
    },
    methods: {
        ...mapActions('auth', ["forgetPassword"]),
        async sendCode() {
            this.loading = true
            await this.forgetPassword(this.email)
            if (this.success) {
                this.successData = true
                this.$router.push('/otp').catch(() => {})
            }else {
                this.successData = false
            }
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_login.scss';
</style>